<template>
  <main
    class="min-h-screen bg-white bg-[radial-gradient(60%_120%_at_50%_50%,hsla(0,0%,100%,0)_0,#DBEEFA_100%)]">
    <div class="mx-auto max-w-6xl">
      <slot />
    </div>
  </main>
</template>

<script lang="ts" setup>
useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: 'h-full bg-gray-50',
  },
})
</script>

<style></style>
